var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',[_vm._v(_vm._s(_vm.$t('Informations')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('The fields with (*) are required')))]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{staticStyle:{"list-style":"none"},attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Language') + '*',"label-for":"language"}},[_c('validation-provider',{attrs:{"name":"profile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.languageOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"profile"},model:{value:(_vm.userData.language),callback:function ($$v) {_vm.$set(_vm.userData, "language", $$v)},expression:"userData.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name') + '*',"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email') + '*',"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email"},on:{"input":_vm.convertToLowerCase},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Customer'),"label-for":"customer"}},[(!_vm.userData.id)?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps},on:{"selected":_vm.selectHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.ownerName),expression:"suggestion.item.ownerName"}]},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('Belongs to: ')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(suggestion.item.ownerName)+" ")])])]}}],null,false,707208954),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e(),(_vm.userData.id)?_c('b-form-input',{attrs:{"id":"customer","disabled":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Profile')+'*',"label-for":"profile"}},[_c('validation-provider',{attrs:{"name":"profile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.profileOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"profile"},model:{value:(_vm.userData.profileId),callback:function ($$v) {_vm.$set(_vm.userData, "profileId", $$v)},expression:"userData.profileId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Password') + '*',"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":_vm.rulesPass()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Timezone*","label-for":"time"}},[_c('validation-provider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time"},model:{value:(_vm.userData.timezone),callback:function ($$v) {_vm.$set(_vm.userData, "timezone", $$v)},expression:"userData.timezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Blocked') + '*',"label-for":"bloked"}},[_c('validation-provider',{attrs:{"name":"blocked","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.blokedOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"bloked"},model:{value:(_vm.userData.blocked),callback:function ($$v) {_vm.$set(_vm.userData, "blocked", $$v)},expression:"userData.blocked"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }