<template>
  <b-card>

    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Section Informations -->
        <h4>{{ $t('Informations') }}</h4>
        <span class="font-weight-bold">{{ $t('The fields with (*) are required') }}</span>
        <hr class="mb-2">
        <b-row>

          <!-- Field: Language -->
          <b-col cols="12" md="2" style="list-style: none;">
            <b-form-group :label="$t('Language') + '*'" label-for="language">
              <validation-provider #default="{ errors }" name="profile" rules="required">
                <v-select :state="errors.length > 0 ? false : null" v-model="userData.language"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="languageOptions"
                          :reduce="val => val.value" :clearable="false" input-id="profile"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

<!--              <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>-->
<!--                <template #button-content>-->
<!--                  <b-img :src="currentLocale.img" height="14px" width="22px"-->
<!--                         :alt="currentLocale.locale"/>-->
<!--                  <span class="ml-50 text-body">{{ currentLocale.name }}</span>-->
<!--                </template>-->
<!--                <b-dropdown-item class="float-left" v-for="localeObj in locales" :key="localeObj.locale"-->
<!--                                 @click="$i18n.locale = localeObj.locale">-->
<!--                  <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale"/>-->
<!--                  <span class="ml-50">{{ localeObj.name }}</span>-->
<!--                </b-dropdown-item>-->
<!--              </b-nav-item-dropdown>-->

            </b-form-group>
          </b-col>

          <!-- Field: Name -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Name') + '*'" label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required|min:3|max:50">
                <b-form-input id="name" v-model="userData.name"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field:  Email -->
          <b-col cols="12" md="6">
            <b-form-group :label="$t('Email') + '*'" label-for="email">
              <validation-provider #default="{ errors }" name="email" rules="required|email|max:255">
                <b-form-input id="email" v-model="userData.email" @input="convertToLowerCase"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Customer -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Customer')" label-for="customer">
              <vue-autosuggest v-if="!userData.id" v-model="searchQuery" :suggestions="filteredOptions"
                               :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  <div v-show="suggestion.item.ownerName">
                    <span class="text-muted">{{ $t('Belongs to: ') }}</span>
                    <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>
                  </div>
                </template>
              </vue-autosuggest>

              <b-form-input v-if="userData.id" id="customer" v-model="searchQuery" disabled/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group :label="$t('Profile')+'*'" label-for="profile">
              <validation-provider #default="{ errors }" name="profile" rules="required">
                <v-select :state="errors.length > 0 ? false : null" v-model="userData.profileId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="profileOptions"
                          :reduce="val => val.value" :clearable="false" input-id="profile"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Password -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Password') + '*'" label-for="password">
              <validation-provider #default="{ errors }" name="password" :rules="rulesPass()">
                <b-form-input id="password" v-model="userData.password" type="password"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field:  Timezone -->
          <b-col cols="12" md="4">
            <b-form-group label="Timezone*" label-for="time">
              <validation-provider #default="{ errors }" name="time" rules="required">
                <b-form-input id="time" v-model="userData.timezone"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Bloked -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Blocked') + '*'" label-for="bloked">
              <validation-provider #default="{ errors }" name="blocked" rules="required">
                <v-select v-model="userData.blocked" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="blokedOptions" :reduce="val => val.value" :clearable="false"
                          input-id="bloked"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              {{ $t('Save') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BNavItemDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from './userStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import {
  VueAutosuggest
} from 'vue-autosuggest'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  max,
  digits,
  alphaDash,
  length,
} from '@validations'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,

    Cleave,
    vSelect,
    VueAutosuggest

  },
  data() {
    return {
      myId: router.currentRoute.params.id,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      max,

      languageOptions:[{label: 'Português', value: 'pt'}, {label: 'English', value: 'en'}],

      options: {

        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,

        },

      },

      blokedOptions: [{
        label: 'Yes',
        value: true
      },
        {
          label: 'No',
          value: false
        },
      ],
      customerOptions: [{
        label: 'Infofleet',
        value: 1
      },
        {
          label: 'Bamaq',
          value: 2
        },
        {
          label: 'Aguarda',
          value: 3
        },
      ],

      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search for your customer ...",
      },
      limit: 3,
      selected: null,
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {

    convertToLowerCase(event) {
      this.userData.email = event.toLowerCase();
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //this.userData.language = this.$i18n.locale
          if (this.userData.id === "") {
            this.userData.email = this.userData.email.toLowerCase()
            store.dispatch('app-user/addUser', {
              userData: this.userData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog(this.$t('Successful registration'), 'success')
                  router.push({
                    name: 'list-user'
                  })

                })
                .catch(error => {
                  this.msgDialog(this.$t('Failed to save'), 'error')
                })
          } else {
            if (!this.userData.password) this.userData.password = ""
            store.dispatch('app-user/updateUser', {

              userData: this.userData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog(this.$t('Successful update'), 'success')
                  this.userData.password = ``
                  //this.clearForm();

                })
                .catch(error => {
                  this.msgDialog(this.$t('FAILURE to update'), 'error')

                })
          }
        }
      })
    },

    rulesPass() {
      console.log('CHAMOU A FUNC')
      if (this.userData.id === "") return 'required|min:4|max:16'
      return ''
    },

    clearForm() {
      this.userData = {
        id: '',
        name: '',
        email: '',
        user: '',
        password: '',
        customerId: '',
        language: 'pt',
        blocked: false
      }
    },

    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },

    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchQuery = option.item.name
      this.userData.customerId = option.item.id

      console.log(`option selecionado `, this.userData.customerId)
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {

        return
      }

      store.dispatch('app-user/fetchCustomers', {
        q: this.searchQuery,

      })
          .then(response => {

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },
    suggestionSelected() {
      console.log('Selecionado', this.selected)
    }
  },
  setup() {
    const userData = ref(null)
    const searchQuery = ref(``)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const locales = [{
      locale: 'en',
      img: require('@/assets/images/flags/en.png'),
      name: 'English',
    },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/br.png'),
        name: 'Português',
      },
      {
        locale: 'es',
        img: require('@/assets/images/flags/ar.png'),
        name: 'Espanõl',
      },
    ]

    const profileOptions = ref([])

    if (router.currentRoute.params.id != undefined) {

      store.dispatch('app-user/fetchUser', {
        //id: router.currentRoute.params.id
        id: router.currentRoute.params.id
      })
          .then(response => {

            userData.value = response.data

            store.dispatch('app-user/fetchCustomer', {
              //id: router.currentRoute.params.id
              id: response.data.customerId
            })
                .then(res => {
                  searchQuery.value = res.data.name;
                  console.log(`nome customer `, res.data.name)

                })
                .catch(error => {

                })
          })
          .catch(e => {
            console.log(e)
          })
    } else {
      userData.value = {
        id: '',
        name: '',
        email: '',
        user: '',
        password: '',
        customerId: null,
        blocked: false,
        language: `pt`,
        timezone: `-3`,
        profileId: ''
      }

    }

    store.dispatch('app-user/fetchProfiles', {
      id: router.currentRoute.params.id
    })
        .then(response => {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach(profile => {
              profileOptions.value.push({label: profile.description, value: profile.id})
            });
          }
        })
        .catch(e => {
          console.log(e)
        })

    return {
      userData,
      searchQuery,
      locales,
      profileOptions
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style>
.title-head {
  margin-bottom: 32px;
}
</style>
