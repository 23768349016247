import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    //.get('/apps/user/users', { params: queryParams })
                    .get(`/api/v1/User/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchUser(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/user?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {

                let user = userData.userData
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/User', user)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateUser(ctx, userData) {
            return new Promise((resolve, reject) => {

                let user = userData.userData
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .put('/api/v1/User', user)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteUser(ctx, {id}) {
            return new Promise((resolve, reject) => {
                console.log('ID STORE', id)
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/User?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCustomers(ctx, queryParams) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Customer/search?Search=${queryParams.q}&Page=1&PerPage=5`)
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCustomer(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/Customer?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchProfiles(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/User/getallprofiles`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
    
}
